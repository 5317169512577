<template>
  <div class="container_title">
    <img src="../../assets/images/logo.png" alt="">
    <span>ECO Carbon Tracer</span>
  </div>
  <div class="container_login">
    <div class="container_login_left">
      <h1>Start Track Your Carbon Footprint</h1>
      <h2>welcome back! By logging in, you can track and manage your environmental footprint in the Carbon
        Footprint Mall</h2>
    </div>
    <div class="container_login_right">
      <!-- 输入用户名、密码 -->
      <div class="container_login_right_from">
        <el-form ref="ruleFormRef" :model="ruleForm" status-icon :rules="rules" label-position="top" class="el_form_item">
          <el-form-item prop="user">
            <el-input class="container_login_right_from_username" v-model="ruleForm.username" type="text" autocomplete="off" placeholder="Enter your user name">
            </el-input>
          </el-form-item>
          <el-form-item prop="pass">
            <el-input class="container_login_right_from_pass" v-model="ruleForm.password" type="password" autocomplete="off" placeholder="Enter your password" show-password />
          </el-form-item>

          <!--                    <div class="el_form_item_forgot_password">-->
          <!--                        forgot password?-->
          <!--                    </div>-->

          <el-form-item>
            <el-button type="primary" @click="logIn(ruleFormRef)">Log in</el-button>
          </el-form-item>
        </el-form>
      </div>

      <!-- 分割线 -->
      <div class="container_login_right_line"></div>
      <div class="container_login_right_signUp">
        New user? <span @click="changLoginShow">Sign Up</span>
      </div>
    </div>
  </div>
</template>

<script setup name="ComponentLogin">
import { reactive, ref, defineEmits, defineProps } from 'vue'
import { setToken, setUserId, setUserType } from '@/utils/auth'
import { getUserInfo, login } from '@/api/user' // 跳转注册
import { useRouter } from 'vue-router'
const ruleFormRef = ref()
const router = useRouter()
const props = defineProps({
  productId: Number,
  index: Number
})
// 验证用户名
const validateUser = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('Required input the username'))
  } else {
    callback()
  }
}

// 验证密码
const validatePass = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('Required input the password'))
  } else {
    callback()
  }
}

const ruleForm = reactive({
  username: '',
  password: ''
})

const rules = reactive({
  user: [{ validator: validateUser, trigger: 'blur' }],
  pass: [{ validator: validatePass, trigger: 'blur' }]
})

const logIn = async (formEl) => {
  if (!formEl) return
  formEl.validate(async (valid) => {
    if (valid) {
      const result = await login(ruleForm)
      if (result.code === 200) {
        setUserId(result.data.userId)
        // 查询当前注册进程
        const res = await getUserInfo(result.data.userId)
        if (res.data != null) {
          if (res.data.registerStep?.step === '1') {
            // 跳转填写步骤三页面
            console.log('aaaaaaa')
            const checked = ref([{ name: 'step', value: '2' }, { name: 'type', value: res.data.registerStep?.type }])
            toSignUp(checked.value)
          } else {
            setToken(result.data.token)
            if (res.data.registerStep?.type === '1') {
              setUserType(1)
              router.push({
                path: 'supplierHome'
              })
            } else {
              setUserType(2)
              if (props.productId != null && props.index != null) {
                router.push({
                  path: 'sendCustomizeRequest',
                  query: {
                    productId: props.productId,
                    index: props.index
                  }
                })
              } else {
                router.push({
                  path: 'home'
                })
              }
            }
          }
        }
      }
    } else {
      return false
    }
  })
}

const emit = defineEmits(['toLoginPage'])

const toSignUp = (data) => {
  emit('toLoginPage', data)
}

const changLoginShow = () => {
  emit('changeShow', false)
}
</script>

<style lang="scss" scoped>
.container {
  &_title {
    margin: 20px auto;
    display: flex;
    justify-content: left;
    margin-left: 100px;
    align-items: center;

    img {
      width: 80px;
      height: 80px;
    }

    span {
      font-size: 30px;
      font-weight: 700;
      line-height: 50px;
      color: rgba(16, 16, 16, 1);
      margin-left: 20px;
    }
  }
  &_login {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;

    &_left {
      //margin-left: 120px;
      text-align: center;
      h1 {
        font-size: 72px;
        line-height: 20px;
        color: rgba(36, 89, 2, 1);
      }

      h2 {
        font-size: 28px;
        line-height: 60px;
        color: rgba(24, 24, 24, 0.8);
        font-weight: 300;
        display: inline-block;
        //width: 600px;
        text-align: justify;
      }
    }

    &_right {
      margin-top: 30px;
      width: 600px;
      // height: 580px;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.4);
      box-shadow: 0px 0px 2px 0px rgba(36, 89, 2, 0.3);
      //margin-right: 360px;



      &_from {
        width: 400px;
        margin: 50px auto;

        .el_form_item {
          &_forgot_password {
            width: 400px;
            font-size: 14px;
            line-height: 1;
            color: rgba(39, 174, 96, 1);
            text-align: center;
            margin: -10px 0 40px;
          }
        }

        ::v-deep .el-form {
          margin: 0 auto;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .el-form-item {
            margin-bottom: 50px;
            width: 400px;
            display: flex;
            flex-direction: column;

            .el-form-item__content {
              .el-input {
                height: 46px;
                height: 60px;

                .el-input__wrapper {
                  padding: 0px 20px;
                  box-shadow: none;
                  background-color: rgba(255, 255, 255, 1);
                  height: 60px;
                  border: 2px solid rgba(173, 173, 173, 0.2);
                  box-sizing: border-box;

                  .el-input__inner {
                    color: rgba(24, 24, 24, 1);
                    font-size: 16px;

                    &::placeholder {
                      color: rgba(173, 173, 173, 0.6);
                    }
                  }
                }

                .is-focus {
                  box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, .1);
                  border: 2px solid rgba(39, 174, 96, 1);
                  box-sizing: border-box;
                }
              }
            }

            .el-button {
              width: 400px;
              height: 60px;
              border-radius: 6px;
              background-color: rgba(39, 174, 96, 1);
              box-shadow: none;
              border: none;
              font-size: 16px;
              line-height: 1;
              font-weight: 700;
            }
          }
        }

        &_username,
        &_pass {
          position: relative;

          ::v-deep .el-input__inner {
            padding-left: 30px;
          }

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 44px;
            height: 100%;
            background: url('../../assets/images/login/login_user1.png') no-repeat;
            background-size: 24px 24px;
            background-position: center right;
            z-index: 99;
          }
        }

        &_pass {

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 44px;
            height: 100%;
            background: url('../../assets/images/login/login_pass.png') no-repeat;
            background-size: 24px 24px;
            background-position: center right;
            z-index: 99;
          }
        }
      }

      &_line {
        width: 400px;
        height: 1px;
        background-color: rgba(173, 173, 173, 0.3);
        margin: -40px auto 40px auto;
      }

      &_signUp {
        width: 600px;
        margin: 0 auto 40px;
        font-size: 24px;
        line-height: 1;
        color: rgba(24, 24, 24, 0.8);
        text-align: center;

        span {
          color: rgba(39, 174, 96, 1);
          cursor: pointer;
          margin-left: 10px;
        }
      }
    }
  }

}
</style>
